/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  materials: [],
  studentDashboardStats: {
    allCourseExercisesCount: 0,
    acceptedExercisesCount: 0,
    declinedExercisesCount: 0,
    verifiableExercisesCount: 0,
    todoExercisesCount: 0,
    approvedExercisesCountLast7Days: 0,
  },
  isLoading: true,
  isLoadingVerifableExercise: false,
  sections: [],
  isEditViewEnabled: false,
  dailyStats: [],
  monthlyStats: [],
  teachersDailyStats: {
    labels: [],
    stats: [],
    getAllTeachersAndAdmins: [],
  },
  teachersMonthlyStats: {
    labels: [],
    stats: [],
    getAllTeachersAndAdmins: [],
  },
  subject: {},
  subjectsPoints: null,
  subjectsUsersPoints: null,
  subjectPoints: [],
  assignedNegativePoints: [],
  negativePoints: [],
  singleSectionSubjects: [],
  materialsByStudentId: [],
  comments: [],
  isLoadingComments: false,
  statuses: [],
  verifiableMaterials: [],
  isLoadingVerifiableMaterials: true,
  reviewedMaterials: [],
  isLoadingReviewedMaterials: true,
  assignedMaterials: [],
  assignedMaterialsDates: [],
  overdueMaterials: [],
  isLoadingOverdueMaterials: true,
  overdueMaterialsDates: [],
  isLoadingAssignedMaterials: true,
  materialStats: [],
  subjectAnswer: {
    subjectId: '',
    content: '',
  },
  assignedSubjectsToTeachers: [],
  allSubjectsAvailableToAssignToTeacher: [],
  allSubjectsAvailableToAssignToTeacherV2: [],

  exerciseQueueJobs: {
    data: [],
    total: 0,
    limit: 10,
    page: 1,
  },
  exerciseQueueJobsStats: {
    total: {},
    queued: {},
    weeklyStats: [],
  },
  coursePoints: {
    data: {
      course: {},
      subjects: [],
    },
    total: 0,
    limit: 10,
    page: 1,
  },
};

export const getMaterials = createAsyncThunk('materials/getMaterials', async () => {
  return API.MaterialsService.getMaterials();
});

export const addMaterial = createAsyncThunk('materials/addMaterial', async payload => {
  return API.MaterialsService.addMaterial(payload);
});

export const getSectionsByMaterialId = createAsyncThunk('materials/getSectionsByMaterialId', async materialId => {
  return API.MaterialsService.getSectionsByMaterialId(materialId);
});

export const addSection = createAsyncThunk('materials/addSection', async payload => {
  return API.MaterialsService.addSection(payload);
});

export const editSection = createAsyncThunk('materials/editSection', async payload => {
  return API.MaterialsService.editSection(payload);
});

export const addSubjectToSection = createAsyncThunk('materials/addSubjectToSection', async payload => {
  return API.MaterialsService.addSubjectToSection(payload);
});

export const editSubject = createAsyncThunk('materials/editSubject', async payload => {
  return API.MaterialsService.editSubject(payload);
});

export const getAssignedNegativePoints = createAsyncThunk(
  'materials/getAssignedNegativePoints',
  async ({ userId, courseId, subjectId }) => {
    return API.MaterialsService.getAssignedNegativePoints(userId, courseId, subjectId);
  },
);

export const addFilesToSubjectContent = createAsyncThunk('materials/addFilesToSubjectContent', async payload => {
  return API.MaterialsService.addFilesToSubjectContent(payload);
});

export const createNewSubjectVersion = createAsyncThunk('materials/createNewSubjectVersion', async payload => {
  return API.MaterialsService.createNewSubjectVersion(payload);
});

export const getSubject = createAsyncThunk('materials/getSubject', async payload => {
  return API.MaterialsService.getSubject(payload);
});

export const getAllSubjectsInSectionBySubjectId = createAsyncThunk(
  'materials/getAllSubjectsInSectionBySubjectId',
  async payload => {
    return API.MaterialsService.getAllSubjectsInSectionBySubjectId(payload);
  },
);

export const getMaterialsByStudentId = createAsyncThunk('materials/getMaterialsByStudentId', async userId => {
  return API.MaterialsService.getMaterialsByStudentId(userId);
});

export const getComments = createAsyncThunk('materials/getComments', async payload => {
  return API.MaterialsService.getComments(payload);
});

export const addComment = createAsyncThunk('materials/addComment', async payload => {
  const data =
    payload.userRole === 'STUDENT'
      ? { text: payload.text, subjectId: payload.subjectId, courseId: payload.courseId }
      : { text: payload.text, subjectId: payload.subjectId, studentId: payload.studentId, courseId: payload.courseId };

  return API.MaterialsService.addComment(data);
});

export const addFilesToComment = createAsyncThunk('materials/addFilesToComment', async payload => {
  return API.MaterialsService.addFilesToComment(payload);
});

export const downloadCommentFile = createAsyncThunk('materials/downloadCommentFile', async payload => {
  return API.MaterialsService.downloadCommentFile(payload);
});

export const downloadSubjectFile = createAsyncThunk('materials/downloadSubjectFile', async payload => {
  return API.MaterialsService.downloadSubjectFile(payload);
});

export const getStatuses = createAsyncThunk('materials/getStatuses', async payload => {
  return API.MaterialsService.getStatuses(payload);
});

export const addStatus = createAsyncThunk('materials/addStatus', async payload => {
  const data =
    payload.userRole === 'STUDENT'
      ? { type: payload.type, subjectId: payload.subjectId, courseId: payload.courseId }
      : { type: payload.type, subjectId: payload.subjectId, userId: payload.userId, courseId: payload.courseId };

  return payload.userRole === 'STUDENT'
    ? API.MaterialsService.addStatusAsStudent(data)
    : API.MaterialsService.addStatus(data);
});
export const addStatusLecture = createAsyncThunk('materials/addStatusLecture', async payload => {
  const data =
    payload.userRole === 'STUDENT'
      ? { type: payload.type, subjectId: payload.subjectId, courseId: payload.courseId }
      : { type: payload.type, subjectId: payload.subjectId, userId: payload.userId, courseId: payload.courseId };

  return payload.userRole === 'STUDENT'
    ? API.MaterialsService.addStatusAsStudent(data)
    : API.MaterialsService.addStatus(data);
});

export const unwatchLecture = createAsyncThunk('materials/unwatchLecture', async payload => {
  const data = { subjectId: payload.subjectId, courseId: payload.courseId };
  API.MaterialsService.unwatchLecture(data);
});

export const assignMaterialToStudent = createAsyncThunk('materials/assignMaterialToStudent', async payload => {
  return API.MaterialsService.assignMaterialToStudent(payload);
});

export const getVerifiableMaterials = createAsyncThunk('materials/getVerifiableMaterials', async () => {
  return API.MaterialsService.getVerifiableMaterials();
});

export const getAssignedToMeVerifiableMaterials = createAsyncThunk(
  'materials/getAssignedVerifiableMaterials',
  async ({ selectedDate }) => {
    return API.MaterialsService.getAssignedVerifiableMaterials({ selectedDate });
  },
);

export const getOverdueVerifiableMaterials = createAsyncThunk(
  'materials/getOverdueVerifiableMaterials',
  async ({ selectedDate, selectedSectionName }) => {
    return API.MaterialsService.getOverdueVerifiableMaterials({ selectedDate, selectedSectionName });
  },
);

export const getRevievedExercises = createAsyncThunk('materials/getRevievedExercises', async () => {
  return API.MaterialsService.getRevievedExercises();
});

export const getExerciseQueueJobsStats = createAsyncThunk('materials/getExerciseQueueJobsStats', async () => {
  return API.MaterialsService.getExerciseQueueJobsStats();
});

export const getExcercisesDailyStats = createAsyncThunk(
  'materials/getExcercisesDailyStats',
  async ({ startDate, endDate }) => {
    return API.MaterialsService.getExcercisesDailyStats({ startDate, endDate });
  },
);

export const getExcercisesMonthlyStats = createAsyncThunk(
  'materials/getExcercisesMonthlyStats',
  async ({ startDate, endDate }) => {
    return API.MaterialsService.getExcercisesMonthlyStats({ startDate, endDate });
  },
);

export const getTeachersExcercisesDailyStats = createAsyncThunk(
  'materials/getTeachersExcercisesDailyStats',
  async ({ startDate, endDate }) => {
    return API.MaterialsService.getTeachersExcercisesDailyStats({ startDate, endDate });
  },
);

export const getTeachersExcercisesMonthlyStats = createAsyncThunk(
  'materials/getTeachersExcercisesMonthlyStats',
  async ({ startDate, endDate }) => {
    return API.MaterialsService.getTeachersExcercisesMonthlyStats({ startDate, endDate });
  },
);

export const getExcercisesPoints = createAsyncThunk('points/getExcercisesPoints', async payload => {
  return API.RewardsService.getExcercisesPoints(payload);
});

export const getUsersCourseExcercisePoints = createAsyncThunk('points/getUsersExcerciesePoints', async payload => {
  return API.RewardsService.getUsersCourseExcercisePoints(payload);
});

export const getUserCourseExcercisePoints = createAsyncThunk('points/getUserCourseExcercisePoints', async payload => {
  return API.RewardsService.getUserCourseExcercisePoints(payload);
});

export const getMaterialStats = createAsyncThunk('materials/getMaterialStats', async payload => {
  return API.MaterialsService.getMaterialStats(payload);
});

export const getSubjectAnswer = createAsyncThunk('materials/getSubjectAnswer', async payload => {
  return API.MaterialsService.getSubjectAnswer(payload);
});

export const editSubjectAnswer = createAsyncThunk('materials/editSubjectAnswer', async payload => {
  return API.MaterialsService.editSubjectAnswer(payload);
});

export const addFilesToSubjectAnswer = createAsyncThunk('materials/addFilesToSubjectAnswer', async payload => {
  return API.MaterialsService.addFilesToSubjectAnswer(payload);
});

export const getDashboardExercisesStudentStats = createAsyncThunk(
  'materials/getDashboardExercisesStudentStats',
  async payload => {
    return API.MaterialsService.getDashboardExercisesStudentStats(payload);
  },
);

export const getAllNegativePoints = createAsyncThunk(
  'materials/getAllNegativePoints',
  async ({ page, limit, search }) => {
    return API.MaterialsService.getAllNegativePoints(page, limit, search);
  },
);

export const getAssignedSubjectsToTeachers = createAsyncThunk(
  'materials/getAssignedSubjectsToTeachers',
  async payload => {
    return API.MaterialsService.getAssignedSubjectsToTeachers(payload);
  },
);

export const getAllSubjectAvailableToAssignToTeacher = createAsyncThunk(
  'materials/getAllSubjectAvailableToAssignToTeacher',
  async payload => {
    return API.MaterialsService.getAllSubjectAvailableToAssignToTeacher(payload);
  },
);

export const getAllSubjectAvailableToAssignToTeacherV2 = createAsyncThunk(
  'materials/getAllSubjectAvailableToAssignToTeacherV2',
  async payload => {
    return API.MaterialsService.getAllSubjectAvailableToAssignToTeacherV2(payload);
  },
);

export const assignSubjectsToTeacher = createAsyncThunk('materials/assignSubjectsToTeacher', async payload => {
  return API.MaterialsService.assignSubjectsToTeacher(payload);
});

export const assignMultipleSubjectsToTeacher = createAsyncThunk(
  'materials/assignMultipleSubjectsToTeacher',
  async payload => {
    return API.MaterialsService.assignMultipleSubjectsToTeacher(payload);
  },
);

export const removeMultipleAssignedSubjectToTeacher = createAsyncThunk(
  'materials/removeMultipleAssignedSubjectToTeacher',
  async payload => {
    return API.MaterialsService.removeMultipleAssignedSubjectToTeacher(payload);
  },
);

export const removeAssignedSubjectToTeacher = createAsyncThunk(
  'materials/removeAssignedSubjectToTeacher',
  async payload => {
    return API.MaterialsService.removeAssignedSubjectToTeacher(payload);
  },
);

export const assignDateToTeacher = createAsyncThunk('materials/assignDateToTeacher', async payload => {
  return API.MaterialsService.assignDateToTeacher(payload);
});

export const removeAssignedDateToTeacher = createAsyncThunk('materials/removeAssignedDateToTeacher', async payload => {
  return API.MaterialsService.removeAssignedDateToTeacher(payload);
});

export const addNewExerciseQueueJob = createAsyncThunk('materials/addNewExerciseQueueJob', async payload => {
  return API.MaterialsService.addNewExerciseQueueJob(payload);
});

export const rateExerciseQueueJob = createAsyncThunk('materials/rateExerciseQueueJob', async payload => {
  return API.MaterialsService.rateExerciseQueueJob(payload);
});

export const getExerciseQueueJobs = createAsyncThunk(
  'materials/getExerciseQueueJobs',
  async ({ pageNo, limit, status, expectedSolutionType }) => {
    return API.MaterialsService.getExerciseQueueJobs({ pageNo, limit, status, expectedSolutionType });
  },
);

export const sendToQueue = createAsyncThunk('materials/sendToQueue', async payload => {
  return API.MaterialsService.sendToQueue(payload);
});

export const getCoursePoints = createAsyncThunk('materials/getCoursePoints', async payload => {
  return API.MaterialsService.getCoursePoints(payload);
});

export const getAllSubjectPoints = createAsyncThunk('materials/getAllSubjectPoints', async subjectId => {
  return API.MaterialsService.getAllSubjectPoints({ subjectId });
});

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    switchEditView: (state, action) => {
      state.isEditViewEnabled = action.payload;
    },
  },
  extraReducers: {
    [getMaterials.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getMaterials.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getMaterials.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.materials = action.payload.data;
    },
    [getSectionsByMaterialId.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getSectionsByMaterialId.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getSectionsByMaterialId.pending.toString()]: (state, action) => {
      state.sections = [];
    },
    [getSectionsByMaterialId.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.sections = action.payload.data;
    },
    [getSubject.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getSubject.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getSubject.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.subject = action.payload.data;
    },
    [getAllSubjectsInSectionBySubjectId.fulfilled.toString()]: (state, action) => {
      state.singleSectionSubjects = action.payload.data;
    },
    [getMaterialsByStudentId.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getMaterialsByStudentId.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getMaterialsByStudentId.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.materialsByStudentId = action.payload.data;
    },
    [getComments.pending.toString()]: state => {
      // if (state.comments.length === 0) {
      //   state.isLoadingComments = true;
      // }
    },
    [getComments.rejected.toString()]: state => {
      // state.isLoadingComments = false;
    },
    [getComments.fulfilled.toString()]: (state, action) => {
      // state.isLoadingComments = false;
      state.comments = action.payload.data.map(status => {
        return { ...status, tag: 'comment' };
      });
    },
    [getStatuses.pending.toString()]: state => {
      state.statuses = [];
    },
    [getStatuses.fulfilled.toString()]: (state, action) => {
      state.statuses = action.payload.data
        .map(status => {
          return { ...status, tag: 'status' };
        })
        .sort((a, b) => {
          return moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1;
        });
    },
    [addMaterial.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać materiału!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addMaterial.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: `Dodano ${action.payload.data.name}!`,
        description: 'Materiał został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [addSection.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać sekcji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addSection.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: `Dodano ${action.payload.data.name}!`,
        description: 'Sekcja została pomyślnie dodana.',
        placement: 'bottomRight',
      });
    },
    [addSubjectToSection.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać tematu!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [addSubjectToSection.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: `Dodano ${action.payload.data.name}!`,
        description: 'Temat został pomyślnie dodany.',
        placement: 'bottomRight',
      });
    },
    [editSubject.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać edycji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [editSubject.fulfilled.toString()]: () => {
      notification.success({
        message: `Dokonano edycji!`,
        description: 'Nowe zmiany zostały zapisane.',
        placement: 'bottomRight',
      });
    },
    [createNewSubjectVersion.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać wersji!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [createNewSubjectVersion.fulfilled.toString()]: () => {
      notification.success({
        message: `Dodano nową wersję!`,
        description: 'Nowa wersja została dodana.',
        placement: 'bottomRight',
      });
    },
    [addStatus.pending.toString()]: state => {
      state.isLoadingVerifableExercise = true;
    },
    [addStatus.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dokonać zmian!',
        description: `${
          action.error.message === 'Status already exist'
            ? 'Zadanie zostało już przesłane do weryfikacji'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
      state.isLoadingVerifableExercise = false;
    },
    [addStatus.fulfilled.toString()]: state => {
      notification.success({
        message: `Zmieniono status ćwiczenia!`,
        description: 'Status ćwiczenia został zmieniony.',
        placement: 'bottomRight',
      });
      state.isLoadingVerifableExercise = false;
    },
    [addStatusLecture.pending.toString()]: state => {
      state.isLoadingVerifableExercise = true;
    },
    [addStatusLecture.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się oznaczyć wykładu jako obejrzany!',
        description: `${
          action.error.message === 'Status already exist' ? 'Wykład był już obejrzany' : action.error.message
        }`,
        placement: 'bottomRight',
      });
      state.isLoadingVerifableExercise = false;
    },
    [addStatusLecture.fulfilled.toString()]: state => {
      state.isLoadingVerifableExercise = false;
    },
    [unwatchLecture.pending.toString()]: state => {
      state.isLoadingVerifableExercise = true;
    },
    [unwatchLecture.rejected.toString()]: state => {
      notification.error({
        message: 'Nie udało się oznaczyć wykładu jako nieobejrzany!',
        placement: 'bottomRight',
      });
      state.isLoadingVerifableExercise = false;
    },
    [unwatchLecture.fulfilled.toString()]: state => {
      state.isLoadingVerifableExercise = false;
    },
    [addComment.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać komentarza!',
        description: `${
          action.error.message === 'Student doesnt assign to material'
            ? 'Uczeń nie jest przypisany do materiału'
            : action.error.message === 'User doesnt have active subscription'
            ? 'Uczeń nie ma aktywnej subskrypcji'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
      state.isLoadingVerifableExercise = false;
    },
    [assignMaterialToStudent.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać studenta!',
        description: `${
          action.error.message === 'User is already assigned'
            ? 'Uczeń jest już przypisany do materiału'
            : action.error.message === 'Material is payable'
            ? 'Materiał jest płatny'
            : action.error.message === 'Material is not assigned to course'
            ? 'Materiał nie jest przypisany do tego kursu'
            : action.error.message === 'Material is not assignable to student'
            ? 'Materiał nie może zostać przypisany do ucznia'
            : action.error.message
        }`,
        placement: 'bottomRight',
      });
    },
    [getRevievedExercises.pending.toString()]: state => {
      state.isLoadingReviewedMaterials = true;
    },
    [getRevievedExercises.rejected.toString()]: state => {
      state.isLoadingReviewedMaterials = false;
    },
    [getRevievedExercises.fulfilled.toString()]: (state, action) => {
      state.isLoadingReviewedMaterials = false;
      state.reviewedMaterials = action.payload.data;
    },
    [getAssignedToMeVerifiableMaterials.pending.toString()]: state => {
      state.isLoadingAssignedMaterials = true;
    },
    [getAssignedToMeVerifiableMaterials.rejected.toString()]: state => {
      state.isLoadingAssignedMaterials = false;
    },
    [getAssignedToMeVerifiableMaterials.fulfilled.toString()]: (state, action) => {
      state.isLoadingAssignedMaterials = false;
      state.assignedMaterials = action.payload.data.data;
      state.assignedMaterialsDates = action.payload.data.availableDates;
    },
    [getOverdueVerifiableMaterials.pending.toString()]: state => {
      state.isLoadingOverdueMaterials = true;
    },
    [getOverdueVerifiableMaterials.rejected.toString()]: state => {
      state.isLoadingOverdueMaterials = false;
    },
    [getOverdueVerifiableMaterials.fulfilled.toString()]: (state, action) => {
      state.isLoadingOverdueMaterials = false;
      state.overdueMaterials = action.payload.data.data;
      state.overdueMaterialsDates = action.payload.data.availableDates;
    },
    [getVerifiableMaterials.pending.toString()]: state => {
      state.isLoadingVerifiableMaterials = true;
    },
    [getVerifiableMaterials.rejected.toString()]: state => {
      state.isLoadingVerifiableMaterials = false;
    },
    [getVerifiableMaterials.fulfilled.toString()]: (state, action) => {
      state.isLoadingVerifiableMaterials = false;
      state.verifiableMaterials = action.payload.data;
    },
    [getAssignedSubjectsToTeachers.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAssignedSubjectsToTeachers.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getAssignedSubjectsToTeachers.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.assignedSubjectsToTeachers = action.payload.data;
    },
    [getAllSubjectAvailableToAssignToTeacher.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllSubjectAvailableToAssignToTeacher.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getAllSubjectAvailableToAssignToTeacher.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.allSubjectsAvailableToAssignToTeacher = action.payload.data;
    },
    [getExcercisesPoints.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getExcercisesPoints.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać punktów z tego zadania',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getExcercisesPoints.fulfilled.toString()]: (state, action) => {
      state.subjectsPoints = action.payload.data;
      state.isLoading = false;
    },
    [getUsersCourseExcercisePoints.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getUsersCourseExcercisePoints.rejected.toString()]: (state, action) => {
      state.isLoading = false;
    },
    [getUsersCourseExcercisePoints.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.subjectsUsersPoints = action.payload.data.assignedPoints;
    },
    [getUserCourseExcercisePoints.fulfilled.toString()]: (state, action) => {
      state.subjectsUsersPoints = action.payload.data.assignedPoints;
    },
    [getMaterialStats.fulfilled.toString()]: (state, action) => {
      state.materialStats = action.payload.data;
    },
    [getSubjectAnswer.fulfilled.toString()]: (state, action) => {
      state.subjectAnswer = action.payload.data;
    },
    [getDashboardExercisesStudentStats.fulfilled.toString()]: (state, action) => {
      state.studentDashboardStats = action.payload.data;
    },
    [getExcercisesDailyStats.fulfilled.toString()]: (state, action) => {
      state.dailyStats = action.payload.data;
    },
    [getExcercisesMonthlyStats.fulfilled.toString()]: (state, action) => {
      state.monthlyStats = action.payload.data;
    },
    [getTeachersExcercisesDailyStats.fulfilled.toString()]: (state, action) => {
      state.teachersDailyStats = action.payload.data;
    },
    [getTeachersExcercisesMonthlyStats.fulfilled.toString()]: (state, action) => {
      state.teachersMonthlyStats = action.payload.data;
    },
    [addNewExerciseQueueJob.fulfilled.toString()]: (state, action) => {},
    [addNewExerciseQueueJob.fulfilled.toString()]: (state, action) => {},
    [addNewExerciseQueueJob.fulfilled.toString()]: (state, action) => {},
    [rateExerciseQueueJob.fulfilled.toString()]: (state, action) => {},
    [rateExerciseQueueJob.fulfilled.toString()]: (state, action) => {},
    [rateExerciseQueueJob.fulfilled.toString()]: (state, action) => {},
    [getExerciseQueueJobs.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getExerciseQueueJobs.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getExerciseQueueJobs.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.exerciseQueueJobs = action.payload.data;
    },
    [getExerciseQueueJobsStats.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getExerciseQueueJobsStats.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getExerciseQueueJobsStats.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.exerciseQueueJobsStats = action.payload.data;
    },
    [sendToQueue.rejected.toString()]: state => {
      notification.error({
        message: 'Nie udało się wysłać do kolejki',
        placement: 'bottomRight',
      });
    },
    [sendToQueue.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Wysłano do kolejki!',
        placement: 'bottomRight',
      });
    },
    [editSubjectAnswer.fulfilled.toString()]: (state, action) => {
      state.subjectAnswer = action.payload.data;
      notification.success({
        message: 'Odpowiedź zapisana!',
        placement: 'bottomRight',
      });
    },
    [editSubjectAnswer.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zapisać odpowiedzi',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignSubjectsToTeacher.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Przypisano zadanie!',
        placement: 'bottomRight',
      });
    },
    [assignSubjectsToTeacher.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać zadania',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeAssignedSubjectToTeacher.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Usunięto przypisania zadania!',
        placement: 'bottomRight',
      });
    },
    [removeAssignedSubjectToTeacher.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć przypisania zadania',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignDateToTeacher.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Przypisano date!',
        placement: 'bottomRight',
      });
    },
    [assignDateToTeacher.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać daty',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeAssignedDateToTeacher.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Usunięto przypisania daty!',
        placement: 'bottomRight',
      });
    },
    [removeAssignedDateToTeacher.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć przypisania daty',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getCoursePoints.fulfilled.toString()]: (state, action) => {
      state.coursePoints.data = action.payload.data.data;
    },
    [editSection.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Edytowano sekcje!',
        placement: 'bottomRight',
      });
    },
    [editSection.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się edytować sekcji',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllSubjectPoints.fulfilled.toString()]: (state, action) => {
      state.subjectPoints = action.payload.data;
    },
    [getAllSubjectPoints.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać punktów z tego zadania',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllSubjectPoints.pending.toString()]: state => {
      state.subjectPoints = [];
    },
    [getAssignedNegativePoints.fulfilled.toString()]: (state, action) => {
      state.assignedNegativePoints = action.payload.data;
    },
    [getAssignedNegativePoints.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać ujemnych punktów z tego zadania',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAssignedNegativePoints.pending.toString()]: state => {
      state.assignedNegativePoints = [];
    },
    [getAllNegativePoints.fulfilled.toString()]: (state, action) => {
      state.negativePoints = action.payload.data;
      state.isLoading = false;
    },
    [getAllNegativePoints.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać ujemnych punktów z tego zadania',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
      state.isLoading = false;
    },
    [getAllNegativePoints.pending.toString()]: state => {
      state.negativePoints = [];
      state.isLoading = true;
    },
    [getAllSubjectAvailableToAssignToTeacherV2.fulfilled.toString()]: (state, action) => {
      state.allSubjectsAvailableToAssignToTeacherV2 = action.payload.data;
    },
    [getAllSubjectAvailableToAssignToTeacherV2.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać dostępnych tematów',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [assignMultipleSubjectsToTeacher.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Przypisano zadania!',
        placement: 'bottomRight',
      });
    },
    [assignMultipleSubjectsToTeacher.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się przypisać zadań',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [removeMultipleAssignedSubjectToTeacher.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Usunięto przypisania zadań!',
        placement: 'bottomRight',
      });
    },
    [removeMultipleAssignedSubjectToTeacher.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć przypisania zadań',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
  },
});

export const { switchEditView } = materialsSlice.actions;

export default materialsSlice.reducer;
