// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const getCalendlyInvitees = ({ page, limit, search, filter, filterCancelled }) =>
  APIService.get(
    `crm/calendly/invitees?page=${page}&limit=${limit}&search=${search}&filter=${filter}&filterCancelled=${filterCancelled}`,
  );

export const getNoPackagesCustomers = ({ page, limit, search, filter }) =>
  APIService.get(`crm/no-packages?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const getCustomer = email => APIService.get(`crm/calendly/customer/${email}`);

export const getActiveStudentActivities = email => APIService.get(`crm/customer/activity/${email}`);

export const getActiveUserOrders = userId => APIService.get(`orders/users/${userId}`);

export const getActiveUserShopOrders = email => APIService.get(`shop/orders/email/${email}`);

export const getActiveStudentTickets = userID => APIService.get(`tickets/user/${userID}`);

export const getModerationList = () => APIService.get('users/moderation');

export const addTask = task => APIService.post('crm/tasks', task);

export const editTask = task => APIService.put('crm/tasks', task);

export const getTasks = ({ page, limit, search, filter }) =>
  APIService.get(`crm/tasks?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const getUserTasks = ({ page, limit, search, filter }) =>
  APIService.get(`crm/tasks/assigned?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const addConversation = conversation => APIService.post('crm/conversations', conversation);

export const addCustomerDetails = customer => APIService.post('crm/customer/details', customer);

export const getCustomerDetails = email => APIService.get(`crm/customer/details/${email}`);

export const getActiveStudentTasks = (email, filter) => APIService.get(`crm/tasks/${email}?filter=${filter}`);

export const getActiveStudentConversations = email => APIService.get(`crm/conversations/${email}`);

export const getActiveStudentCalendlyInvitees = email => APIService.get(`crm/calendly/invitees/${email}`);
