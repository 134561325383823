import React, { useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Row, Col, Tooltip } from 'antd';
import { SettingWrapper } from '../../myProfile/styleSettings';
import { getStudentsOrders } from '../../../ducks/orders';

const UserCartCoverSection = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { orders } = useSelector(state => {
    return {
      orders: state.orders.orders,
    };
  });
  useEffect(() => {
    dispatch(getStudentsOrders());
  }, [dispatch]);

  const hasFinishedOrder = orders?.some(order => order?.orderLastStatus?.status === 'FINISHED');
  const backgroundImage =
    process.env.REACT_APP_THEME === 'KMM'
      ? require('../../../static/img/settings-background-image-kmm.png')
      : require('../../../static/img/settings-background-image.jpg');

  return (
    <Row gutter={25} className="subscriptions-cover-section-my-account-content-row">
      <Col md={24} xs={24} className="mobile-my-account-content-col">
        <SettingWrapper className="mobile-my-account-settngs-wrapper">
          <Col md={24} xs={24} className="author-box-col">
            <div className="cover-image">
              <img style={{ width: '100%' }} src={backgroundImage} alt="banner" />
            </div>
          </Col>

          <nav className="subscriptons-menu">
            <ul className="links-list" id="links-cart-nav">
              {user.paymentType === 'PACKAGES_V2' && (
                <li>
                  <NavLink
                    className={location.pathname === `/shop` ? 'navlink-style selected' : 'navlink-style normal'}
                    to="/shop"
                  >
                    Sklep
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={
                    location?.pathname === `/shop/cart` || location?.pathname.includes('checkout')
                      ? 'navlink-style selected'
                      : 'navlink-style normal'
                  }
                  to="/shop/cart"
                >
                  Koszyk
                </NavLink>
              </li>
              {!hasFinishedOrder ? (
                <Tooltip title="Skorzystaj z kodu polecającego i zniżki po zakupie min. pierwszego pakietu">
                  <li>
                    <NavLink className="navlink-style blocked cart" to="">
                      <FeatherIcon
                        strokeWidth={2}
                        icon="lock"
                        size={12}
                        color="#878EAA"
                        style={{ marginRight: '5px' }}
                      />
                      Kod polecający
                    </NavLink>
                  </li>
                </Tooltip>
              ) : (
                <li>
                  <NavLink
                    className={
                      location?.pathname === `/shop/referral-code` ? 'navlink-style selected' : 'navlink-style normal'
                    }
                    to="/shop/referral-code"
                  >
                    Kod polecający
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink
                  className={
                    !user.isActive
                      ? 'navlink-style blocked subs-menu'
                      : location?.pathname === `/shop/orders`
                      ? 'navlink-style selected'
                      : 'navlink-style normal'
                  }
                  to={user?.isActive ? '/shop/orders' : ''}
                >
                  {!user?.isActive && (
                    <FeatherIcon strokeWidth={2} icon="lock" size={10} color="#878EAA" style={{ marginRight: '5px' }} />
                  )}
                  Historia zamówień
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className={
                    !user.isActive
                      ? 'navlink-style blocked subs-menu'
                      : location?.pathname === `/referral-code`
                      ? 'navlink-style selected'
                      : 'navlink-style'
                  }
                  to={user?.isActive ? '/referral-code' : ''}
                >
                  {!user?.isActive && (
                    <FeatherIcon strokeWidth={2} icon="lock" size={10} color="#878EAA" style={{ marginRight: '5px' }} />
                  )}
                  Kod polecający
                </NavLink>
              </li> */}
            </ul>
          </nav>
        </SettingWrapper>
      </Col>
    </Row>
  );
};

export default UserCartCoverSection;
