import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { MenuFontProvider } from './bottomMenuStyle';
import { kmiTheme, kmmTheme } from '../config/theme/kmiAndKmmThemes';
import config from '../config/config';

const { theme } = config;
const kmiOrKmmTheme = process.env.REACT_APP_THEME === 'KMM' ? kmmTheme : kmiTheme;
const allThemes = { ...kmiOrKmmTheme };

const MobileBottomMenu = ({ darkMode }) => {
  const location = useLocation();

  const { user, studentCourses, courses } = useSelector(state => {
    return {
      user: state.user.user,
      studentCourses: state.students.studentCourses,
      courses: state.courses.courses,
    };
  });
  const coursesToDisplay = user.role === 'STUDENT' ? studentCourses : courses;

  return (
    <Menu
      theme={darkMode && 'dark'}
      disabled={user.isActive === false}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      selectedKeys={[location.pathname]}
      style={{ display: 'flex', maxHeight: '46px' }}
    >
      <MenuFontProvider id={!user.isActive ? 'inactive-footer' : 'active-footer'}>
        {coursesToDisplay.map(course => {
          return (
            <>
              {user.role === 'STUDENT' && (
                <NavLink
                  to={user.isActive ? `/courses/${course.courseId ? course.courseId : course._id}/dashboard` : ''}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/dashboard`}
                    icon={
                      <FeatherIcon
                        icon={user.isActive ? 'home' : 'lock'}
                        style={{
                          color:
                            location.pathname ===
                              `/courses/${course.courseId ? course.courseId : course._id}/dashboard` ||
                            location.pathname.includes('dashboard')
                              ? allThemes['--primary-buttons-color']
                              : theme['light-gray-color'],
                          strokeWidth: 2,
                        }}
                      />
                    }
                    className={
                      location.pathname === `/courses/${course.courseId ? course.courseId : course._id}/dashboard` ||
                      location.pathname.includes('dashboard')
                        ? 'selected-menu-item first-item-top-list'
                        : 'normal-menu-item first-item-top-list'
                    }
                  >
                    Panel kursu
                  </Menu.Item>
                </NavLink>
              )}
              {user.paymentType === 'PACKAGES_V1' && (
                <NavLink
                  to={{
                    pathname: user.isActive
                      ? `/courses/${course.courseId ? course.courseId : course._id}/materials`
                      : '',
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/materials`}
                    icon={
                      <FeatherIcon
                        icon={user.isActive ? 'archive' : 'lock'}
                        style={{
                          color: location.pathname.includes('/materials')
                            ? allThemes['--primary-buttons-color']
                            : theme['light-gray-color'],
                          strokeWidth: 2,
                        }}
                      />
                    }
                    className={location.pathname.includes('/materials') ? 'selected-menu-item' : 'normal-menu-item'}
                  >
                    Materiały
                  </Menu.Item>
                </NavLink>
              )}
              {(user.paymentType === 'PACKAGES_V2' || user.paymentType === 'SUBSCRIPTIONS_V1') && (
                <NavLink
                  to={{
                    pathname: user.isActive ? `/courses/${course.courseId ? course.courseId : course._id}/modules` : '',
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/modules`}
                    icon={
                      <FeatherIcon
                        icon={user.isActive ? 'archive' : 'lock'}
                        style={{
                          color: location.pathname.includes('/modules')
                            ? allThemes['--primary-buttons-color']
                            : theme['light-gray-color'],
                          strokeWidth: 2,
                        }}
                      />
                    }
                    className={location.pathname.includes('/modules') ? 'selected-menu-item' : 'normal-menu-item'}
                  >
                    Moduły
                  </Menu.Item>
                </NavLink>
              )}
              {user.role === 'STUDENT' && process.env.REACT_APP_THEME === 'KMI' && (
                <NavLink to="/quiz">
                  <Menu.Item
                    id="questions-base-hover"
                    key="/quiz"
                    icon={
                      <FeatherIcon
                        icon="book-open"
                        style={{
                          color:
                            location.pathname === `/quiz`
                              ? allThemes['--primary-buttons-color']
                              : theme['light-gray-color'],
                          strokeWidth: 2,
                        }}
                      />
                    }
                    className={location.pathname === `/quiz` ? 'selected-menu-item' : 'normal-menu-item'}
                  >
                    Quiz
                  </Menu.Item>
                </NavLink>
              )}
              {(user.role === 'STUDENT' || user.role === 'TEACHER') && (
                <NavLink to="/tickets/all">
                  <Menu.Item
                    key="/tickets/all"
                    id="tickets-base-hover"
                    icon={
                      <FeatherIcon
                        icon="flag"
                        style={{
                          color:
                            location.pathname === `/tickets/all`
                              ? allThemes['--primary-buttons-color']
                              : theme['light-gray-color'],
                          strokeWidth: 2,
                        }}
                      />
                    }
                    className={location.pathname === `/tickets/all` ? 'selected-menu-item' : 'normal-menu-item'}
                  >
                    Moje zgłoszenia
                  </Menu.Item>
                </NavLink>
              )}
            </>
          );
        })}
      </MenuFontProvider>
    </Menu>
  );
};

MobileBottomMenu.propTypes = {
  darkMode: propTypes.bool,
};

export default MobileBottomMenu;
