import React, { useEffect } from 'react';
import { Menu, Space, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { MenuFontProvider } from './style';
import config from '../config/config';
import { getTickets } from '../ducks/tickets';
import user from '../ducks/user';

const { version } = require('../../package.json');

const { theme } = config;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, collapsed }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { user, studentCourses, courses } = useSelector(state => {
    return {
      user: state.user.user,
      studentCourses: state.students.studentCourses,
      courses: state.courses.courses,
    };
  });
  const coursesToDisplay = user.role === 'STUDENT' ? studentCourses : courses;
  const { tickets } = useSelector(state => {
    return {
      tickets: state.tickets.tickets,
      user: state.user.user,
    };
  });

  useEffect(() => {
    dispatch(getTickets());
  }, [dispatch]);

  const getFirstCharsFromString = React.useCallback(string => {
    return string
      .split(' ')
      .map(word => word[0])
      .reduce((a, b) => a + b);
  }, []);

  const openedTickets = tickets.filter(ticket => ticket.ticketLastStatus.type === 'OPEN').length;
  const notAnsweredTickets = tickets.filter(ticket => {
    const isOpen = ticket.ticketLastStatus.type === 'OPEN';
    const isAnswered = ticket.userId !== ticket.ticketLastAnswer?.userId;
    return isOpen && !isAnswered;
  }).length;

  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      disabled={user.isActive === false}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      selectedKeys={[location.pathname]}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <MenuFontProvider>
        {user.role !== 'STUDENT' && (
          <>
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="smile" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>CRM</Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/students/v2">
                <Menu.Item key="/students/v2" icon={<FeatherIcon icon="users" />}>
                  Studenci V2
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/crm/calendly">
                <Menu.Item key="/crm/calendly" icon={<FeatherIcon icon="calendar" />}>
                  Calendly
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/crm/tasks">
                <Menu.Item key="/crm/tasks" icon={<FeatherIcon icon="briefcase" />}>
                  Zadania
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/crm/no-packages">
                <Menu.Item key="/crm/no-packages" icon={<FeatherIcon icon="users" />}>
                  Studenci bez pakietów
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="check-square" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>ZADANIA</Typography.Text>
              </Menu.Item>
            )}
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/verify">
                <Menu.Item key="/verify" icon={<FeatherIcon icon="check-square" />}>
                  Zadania do sprawdzenia
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'TEACHER' || user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/my-verify">
                <Menu.Item key="/my-verify" icon={<FeatherIcon icon="clipboard" />}>
                  Moje zadania do sprawdzenia
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'TEACHER' || user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/crm/tasks/assigned">
                <Menu.Item key="/crm/tasks/assigned" icon={<FeatherIcon icon="briefcase" />}>
                  Moje zadania
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'TEACHER' || user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/verify/overdue">
                <Menu.Item key="/verify/overdue" icon={<FeatherIcon icon="sunset" />}>
                  Zaległe zadania do sprawdzenia
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'TEACHER' || user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/exercises-queue">
                <Menu.Item key="/exercises-queue" icon={<FeatherIcon icon="smile" />}>
                  D.A.R.E.K
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/exercises/settings">
                <Menu.Item key="/exercises/settings" icon={<FeatherIcon icon="sliders" />}>
                  Ustawienia zadań do sprawdzenia
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/exercises/settings/v2">
                <Menu.Item key="/exercises/settings/v2" icon={<FeatherIcon icon="sliders" />}>
                  (V2) Ustawienia zadań do sprawdzenia
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="bar-chart" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>
                  STATYSTYKI
                </Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/exercises">
                <Menu.Item key="/exercises" icon={<FeatherIcon icon="bar-chart" />}>
                  Zadania statystyki
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/exercises/stats">
                <Menu.Item key="/exercises/stats" icon={<FeatherIcon icon="bar-chart-2" />}>
                  Statystyki sprawdzania zadań
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/exercises/recent">
                <Menu.Item key="/exercises/recent" icon={<FeatherIcon icon="clock" />}>
                  Ostatnie sprawdzania zadań
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/exercises/negative-points">
                <Menu.Item key="/exercises/negative-points" icon={<FeatherIcon icon="clock" />}>
                  Ostatnie ujemne punkty
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/carts">
                <Menu.Item key="/carts" icon={<FeatherIcon icon="shopping-cart" />}>
                  Koszyki statystyki
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="archive" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>KURS</Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/quizquestions">
                <Menu.Item key="/quizquestions" icon={<FeatherIcon icon="list" />}>
                  Quiz
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN' || user.role === 'TEACHER') && (
              <NavLink onClick={toggleCollapsed} to="/materials">
                <Menu.Item key="/materials" icon={<FeatherIcon icon="archive" />}>
                  Materiały globalne
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/courses">
                <Menu.Item key="/courses" icon={<FeatherIcon icon="video" />}>
                  Kursy
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/products">
                <Menu.Item key="products" icon={<FeatherIcon icon="package" />}>
                  Produkty
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="send" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>
                  OPERACJE
                </Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/coupons">
                <Menu.Item key="/coupons" icon={<FeatherIcon icon="percent" />}>
                  Kupony
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/accesscodes">
                <Menu.Item key="/accesscodes" icon={<FeatherIcon icon="award" />}>
                  Kody dostępu
                </Menu.Item>
              </NavLink>
            )}
            ,
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/multimedia">
                <Menu.Item key="/multimedia" icon={<FeatherIcon icon="image" />}>
                  Multimedia
                </Menu.Item>
              </NavLink>
            )}
            {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
              <NavLink onClick={toggleCollapsed} to="/material-videos">
                <Menu.Item key="/material-videos" icon={<FeatherIcon icon="film" />}>
                  Filmiki
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/ratings">
                <Menu.Item key="/ratings" icon={<FeatherIcon icon="star" />}>
                  Oceny wykładów
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/students/warnings">
                <Menu.Item key="/students/warnings" icon={<FeatherIcon icon="alert-triangle" />}>
                  Ostrzeżenia
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/students">
                <Menu.Item key="/students" icon={<FeatherIcon icon="users" />}>
                  Studenci
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/students/v2">
                <Menu.Item key="/students/v2" icon={<FeatherIcon icon="users" />}>
                  Studenci V2
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/students/activeaccountnosubscription">
                <Menu.Item key="/students/activeaccountnosubscription" icon={<FeatherIcon icon="users" />}>
                  Studenci z aktywny kontem bez subskrypcji
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/rewards" icon={<FeatherIcon icon="gift" />}>
                <NavLink onClick={toggleCollapsed} to="/rewards">
                  Nagrody
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/user-tokens">
                <Menu.Item key="/user-tokens" icon={<FeatherIcon icon="zap" />}>
                  Tokeny Logowania
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="truck" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>SKLEP</Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/shop-external/orders" icon={<FeatherIcon icon="truck" />}>
                <NavLink onClick={toggleCollapsed} to="/shop-external/orders">
                  Sklep - Zamówienia
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/shop-external/products" icon={<FeatherIcon icon="package" />}>
                <NavLink onClick={toggleCollapsed} to="/shop-external/products">
                  Sklep - Produkty
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/shop-external/depot" icon={<FeatherIcon icon="package" />}>
                <NavLink onClick={toggleCollapsed} to="/shop-external/depot">
                  Sklep - Magazyn
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/shop-external/promocodes" icon={<FeatherIcon icon="percent" />}>
                <NavLink onClick={toggleCollapsed} to="/shop-external/promocodes">
                  Sklep - Kody Rabatowe
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="package" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>PAKIETY</Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <NavLink onClick={toggleCollapsed} to="/coupons/recommendations">
                <Menu.Item key="/coupons/recommendations" icon={<FeatherIcon icon="smile" />}>
                  Pakiety - kody rekomendacji
                </Menu.Item>
              </NavLink>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item
                style={{ borderBottom: '1px solid grey', borderTop: '1px solid grey' }}
                icon={<FeatherIcon icon="rotate-cw" style={{ color: theme['light-gray-color'] }} />}
              >
                <Typography.Text style={{ color: theme['light-gray-color'], fontWeight: 700 }}>
                  SUBSKRYPCJE
                </Typography.Text>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/subscriptions/manage-subscription" icon={<FeatherIcon icon="rotate-cw" />}>
                <NavLink onClick={toggleCollapsed} to="/subscriptions/manage-subscription">
                  Subskrypcje
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/subscriptions/invoices" icon={<FeatherIcon icon="file-text" />}>
                <NavLink onClick={toggleCollapsed} to="/subscriptions/invoices">
                  Subskrypcje - Faktury
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/subscriptions/recommendations" icon={<FeatherIcon icon="smile" />}>
                <NavLink onClick={toggleCollapsed} to="/subscriptions/recommendations">
                  Subskrypcje - Rekomendacje
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/subscriptions/cancel-reasons" icon={<FeatherIcon icon="x-circle" />}>
                <NavLink onClick={toggleCollapsed} to="/subscriptions/cancel-reasons">
                  Subskrypcje - Powody anulowania subskrypcji
                </NavLink>
              </Menu.Item>
            )}
            {user.role === 'ADMIN' && (
              <Menu.Item key="/subscriptions/refunds" icon={<FeatherIcon icon="corner-down-left" />}>
                <NavLink onClick={toggleCollapsed} to="/subscriptions/refunds">
                  Subskrypcje - Zwroty
                </NavLink>
              </Menu.Item>
            )}
          </>
        )}
        {coursesToDisplay.map(course => {
          return (
            <>
              <Menu.Item
                icon={
                  collapsed ? (
                    <p style={{ color: theme['light-gray-color'] }}>
                      {getFirstCharsFromString(course.name ? course.name : course.course.name.toUpperCase())}
                    </p>
                  ) : null
                }
              >
                <div className="sider-menu-header">
                  <Typography.Text
                    className="sider-menu-header-text"
                    style={{
                      color: theme['light-gray-color'],
                      lineHeight: '20px',
                    }}
                  >
                    KURS:
                  </Typography.Text>
                  <Typography.Text
                    className="sider-menu-header-text"
                    style={{ color: theme['light-gray-color'], lineHeight: '20px' }}
                  >
                    {course.name ? course.name : course.course.name.toUpperCase()}
                  </Typography.Text>
                </div>
              </Menu.Item>
              {user.role === 'STUDENT' && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={
                    user.isActive || (!user.isActive && user.hasUserBoughtAnySubscription)
                      ? `/courses/${course.courseId ? course.courseId : course._id}/dashboard`
                      : ''
                  }
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/dashboard`}
                    icon={
                      <FeatherIcon
                        height={15}
                        icon={user.isActive || (!user.isActive && user.hasUserBoughtAnySubscription) ? 'home' : 'lock'}
                        style={{
                          color:
                            location.pathname ===
                              `/courses/${course.courseId ? course.courseId : course._id}/dashboard` ||
                            location.pathname.includes('dashboard')
                              ? theme['white-color']
                              : theme['light-gray-color'],
                          strokeWidth: 3,
                        }}
                      />
                    }
                    className={
                      location.pathname === `/courses/${course.courseId ? course.courseId : course._id}/dashboard` ||
                      location.pathname.includes('dashboard')
                        ? 'selected-menu-item first-item-top-list'
                        : 'normal-menu-item first-item-top-list'
                    }
                  >
                    Panel kursu
                  </Menu.Item>
                </NavLink>
              )}
              {user.role === 'STUDENT' &&
                user.paymentType === 'PACKAGES_V1' &&
                !user.isActiveSubscriptionPayment &&
                user.paymentType !== 'PACKAGES_V2' &&
                user.paymentType !== 'SUBSCRIPTIONS_V1' && (
                  <NavLink
                    onClick={toggleCollapsed}
                    to={{
                      pathname:
                        user.role === 'STUDENT' &&
                        user.paymentType === 'PACKAGES_V1' &&
                        !user.isActiveSubscriptionPayment
                          ? `/courses/${course.courseId ? course.courseId : course._id}/materials`
                          : '',
                      state: { courseLevelId: course?.courseLevel?._id },
                    }}
                  >
                    <Menu.Item
                      key={`/courses/${course.courseId ? course.courseId : course._id}/materials`}
                      icon={
                        <FeatherIcon
                          height={15}
                          icon={user.isActive ? 'archive' : 'lock'}
                          style={{
                            color:
                              user.role === 'STUDENT' && location.pathname.includes('/materials')
                                ? theme['white-color']
                                : theme['light-gray-color'],
                            strokeWidth: 3,
                          }}
                        />
                      }
                      className={
                        user.role === 'STUDENT' && location.pathname.includes('/materials')
                          ? 'selected-menu-item'
                          : 'normal-menu-item'
                      }
                    >
                      Materiały
                    </Menu.Item>
                  </NavLink>
                )}
              {user.role !== 'STUDENT' && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={{
                    pathname: user.isActive
                      ? `/courses/${course.courseId ? course.courseId : course._id}/materials`
                      : '',
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/materials`}
                    icon={<FeatherIcon icon={user.isActive ? 'archive' : 'lock'} />}
                  >
                    Materiały
                  </Menu.Item>
                </NavLink>
              )}
              {(user.role === 'ADMIN' || user.role === 'SENIOR-TEACHER') && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={{
                    pathname: user.isActive
                      ? `/courses/${course.courseId ? course.courseId : course._id}/edit/faq`
                      : '',
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/edit/faq`}
                    icon={<FeatherIcon icon="help-circle" />}
                  >
                    FAQ
                  </Menu.Item>
                </NavLink>
              )}
              {(user.role === 'ADMIN' || user.role === 'SENIOR-TEACHER') && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={{
                    pathname: user.isActive
                      ? `/courses/${course.courseId ? course.courseId : course._id}/edit/modules`
                      : '',
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/edit/modules`}
                    icon={<FeatherIcon icon="edit" />}
                  >
                    Edycja modułów
                  </Menu.Item>
                </NavLink>
              )}
              {(user.role === 'ADMIN' || user.role === 'SENIOR-TEACHER') && (
                <>
                  <NavLink
                    onClick={toggleCollapsed}
                    to={{
                      pathname: user.isActive
                        ? `/courses/${course.courseId ? course.courseId : course._id}/edit/negative-points`
                        : '',
                      state: { courseLevelId: course?.courseLevel?._id },
                    }}
                  >
                    <Menu.Item
                      key={`/courses/${course.courseId ? course.courseId : course._id}/edit/negative-points`}
                      icon={<FeatherIcon icon="minus-square" />}
                    >
                      Punkty ujemne
                    </Menu.Item>
                  </NavLink>
                </>
              )}
              {user.role === 'ADMIN' && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={{
                    pathname: user.isActive ? `/courses/${course.courseId ? course.courseId : course._id}/points` : '',
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/points`}
                    icon={<FeatherIcon icon="list" />}
                  >
                    Punkty
                  </Menu.Item>
                </NavLink>
              )}
              {process.env.REACT_APP_IS_NEW_MODULES_ENABLED === 'true' &&
                (user.isActiveSubscriptionPayment ||
                  user.paymentType === 'PACKAGES_V2' ||
                  user.paymentType === 'SUBSCRIPTIONS_V1') &&
                user.paymentType !== 'PACKAGES_V1' &&
                user.role === 'STUDENT' && (
                  <NavLink
                    onClick={toggleCollapsed}
                    to={user.isActive ? `/courses/${course.courseId ? course.courseId : course._id}/modules` : ''}
                  >
                    <Menu.Item
                      key={`/courses/${course.courseId ? course.courseId : course._id}/modules`}
                      id={user.role === 'STUDENT' && user.isActive && 'rewards-base-hover'}
                      icon={
                        <FeatherIcon
                          height={15}
                          icon={user.isActive ? 'archive' : 'lock'}
                          style={{
                            color:
                              location.pathname ===
                                `/courses/${course.courseId ? course.courseId : course._id}/modules` ||
                              location.pathname.includes('materials')
                                ? theme['white-color']
                                : theme['light-gray-color'],
                            strokeWidth: 3,
                          }}
                        />
                      }
                      className={
                        location.pathname === `/courses/${course.courseId ? course.courseId : course._id}/modules` ||
                        location.pathname.includes('materials')
                          ? 'selected-menu-item'
                          : 'normal-menu-item'
                      }
                    >
                      Moduły
                    </Menu.Item>
                  </NavLink>
                )}
              {user.role === 'STUDENT' && process.env.REACT_APP_THEME === 'KMI' && (
                <NavLink onClick={toggleCollapsed} to="/quiz">
                  <Menu.Item
                    id={
                      user.role === 'STUDENT' &&
                      (user.isActive || (!user.isActive && user.hasUserBoughtAnySubscription)) &&
                      'questions-base-hover'
                    }
                    key="/quiz"
                    icon={
                      <FeatherIcon
                        height={15}
                        icon="book-open"
                        style={{
                          color: location.pathname === `/quiz` ? theme['white-color'] : theme['light-gray-color'],
                          strokeWidth: 3,
                        }}
                      />
                    }
                    className={location.pathname === `/quiz` ? 'selected-menu-item' : 'normal-menu-item'}
                  >
                    Quiz
                  </Menu.Item>
                </NavLink>
              )}
              {process.env.REACT_APP_IS_QUESTION_DATABASE_ENABLED === 'true' && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={`/courses/${course.courseId ? course.courseId : course._id}/question-database`}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/question-database`}
                    id={
                      user.role === 'STUDENT' &&
                      (user.isActive || (!user.isActive && user.hasUserBoughtAnySubscription)) &&
                      'rewards-base-hover'
                    }
                    icon={
                      <FeatherIcon
                        height={15}
                        icon="book-open"
                        style={{
                          color:
                            location.pathname ===
                            `/courses/${course.courseId ? course.courseId : course._id}/question-database`
                              ? theme['white-color']
                              : theme['light-gray-color'],
                          strokeWidth: 3,
                        }}
                      />
                    }
                    className={
                      location.pathname ===
                      `/courses/${course.courseId ? course.courseId : course._id}/question-database`
                        ? 'selected-menu-item'
                        : 'normal-menu-item'
                    }
                  >
                    Baza pytań
                  </Menu.Item>
                </NavLink>
              )}
              {user.role === 'STUDENT' && process.env.REACT_APP_THEME === 'KMI' && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={`/courses/${course.courseId ? course.courseId : course._id}/rewards`}
                >
                  <Menu.Item
                    key={`/courses/${course.courseId ? course.courseId : course._id}/rewards`}
                    id={
                      user.role === 'STUDENT' &&
                      (user.isActive || (!user.isActive && user.hasUserBoughtAnySubscription)) &&
                      'rewards-base-hover'
                    }
                    icon={
                      <FeatherIcon
                        height={15}
                        icon="gift"
                        style={{
                          color:
                            location.pathname === `/courses/${course.courseId ? course.courseId : course._id}/rewards`
                              ? theme['white-color']
                              : theme['light-gray-color'],
                          strokeWidth: 3,
                        }}
                      />
                    }
                    className={
                      location.pathname === `/courses/${course.courseId ? course.courseId : course._id}/rewards`
                        ? 'selected-menu-item'
                        : 'normal-menu-item'
                    }
                  >
                    Nagrody
                  </Menu.Item>
                </NavLink>
              )}
              {user.role === 'STUDENT' && process.env.REACT_APP_IS_FAQ_ENABLED === 'true' && (
                <NavLink
                  onClick={toggleCollapsed}
                  to={{
                    pathname: `/courses/${course.courseId ? course.courseId : course._id}/faqs`,
                    state: { courseLevelId: course?.courseLevel?._id },
                  }}
                >
                  <Menu.Item
                    key="/orders"
                    icon={
                      <FeatherIcon
                        height={15}
                        icon="help-circle"
                        style={{
                          color:
                            user.role === 'STUDENT' && location.pathname === `/faqs`
                              ? theme['white-color']
                              : theme['light-gray-color'],
                          strokeWidth: 3,
                        }}
                      />
                    }
                    className={
                      user.role === 'STUDENT' && location.pathname === `/faqs`
                        ? 'selected-menu-item'
                        : 'normal-menu-item'
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    FAQs
                  </Menu.Item>
                </NavLink>
              )}
            </>
          );
        })}
        <div className="hr-div" />
        {user.role === 'STUDENT' && user.paymentType === 'PACKAGES_V2' && (
          <NavLink onClick={toggleCollapsed} to={user.isActive && user.paymentType === 'PACKAGES_V2' ? '/shop' : ''}>
            <Menu.Item
              key="/shop"
              icon={
                <FeatherIcon
                  height={15}
                  icon={user.isActive && user.paymentType === 'PACKAGES_V2' ? 'shopping-bag' : 'lock'}
                  style={{
                    color:
                      user.role === 'STUDENT' &&
                      (location.pathname === `/shop` ||
                        location.pathname === `/shop/cart` ||
                        location.pathname === `/shop/orders` ||
                        location.pathname === `/shop/referral-code` ||
                        location.pathname === `/shop/cart/checkout` ||
                        location.pathname.includes('successed') ||
                        location.pathname.includes('failed'))
                        ? theme['white-color']
                        : theme['light-gray-color'],
                    strokeWidth: 3,
                  }}
                />
              }
              className={
                user.role === 'STUDENT' &&
                (location.pathname === `/shop` ||
                  location.pathname === `/shop/cart` ||
                  location.pathname === `/shop/orders` ||
                  location.pathname === `/shop/referral-code` ||
                  location.pathname === `/shop/cart/checkout` ||
                  location.pathname.includes('successed') ||
                  location.pathname.includes('failed'))
                  ? 'selected-menu-item'
                  : 'normal-menu-item'
              }
            >
              Sklep
            </Menu.Item>
          </NavLink>
        )}
        {/* {user.role === 'STUDENT' && (user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2') && (
          <NavLink onClick={toggleCollapsed} to={user.isActive ? '/shop/cart' : ''}>
            <Menu.Item
              key="/shop/cart"
              icon={
                <FeatherIcon
                  height={15}
                  icon={
                    user.isActive && (user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2')
                      ? 'shopping-cart'
                      : 'lock'
                  }
                  style={{
                    color: location.pathname === `/shop/cart` ? theme['white-color'] : theme['light-gray-color'],
                    strokeWidth: 3,
                  }}
                />
              }
              className={location.pathname === `/shop/cart` ? 'selected-menu-item' : 'normal-menu-item'}
            >
              Koszyk
            </Menu.Item>
          </NavLink>
        )} */}
        {user.role === 'ADMIN' && (
          <NavLink onClick={toggleCollapsed} to={user.isActive ? '/orders' : ''}>
            <Menu.Item key="/orders" icon={<FeatherIcon icon={user.isActive ? 'file-text' : 'lock'} />}>
              Zamówienia
            </Menu.Item>
          </NavLink>
        )}
        {/* {user.role === 'STUDENT' && (user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2') && (
          <NavLink
            onClick={toggleCollapsed}
            to={
              user.isActive && (user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2')
                ? '/shop/orders'
                : ''
            }
          >
            <Menu.Item
              key="/shop/orders"
              icon={
                <FeatherIcon
                  height={15}
                  icon={
                    user.isActive && (user.paymentType === 'PACKAGES_V1' || user.paymentType === 'PACKAGES_V2')
                      ? 'file'
                      : 'lock'
                  }
                  style={{
                    color:
                      user.role === 'STUDENT' && location.pathname === `/shop/orders`
                        ? theme['white-color']
                        : theme['light-gray-color'],
                    strokeWidth: 3,
                  }}
                />
              }
              className={
                user.role === 'STUDENT' && location.pathname === `/shop/orders`
                  ? 'selected-menu-item'
                  : 'normal-menu-item'
              }
            >
              Zamówienia
            </Menu.Item>
          </NavLink>
        )} */}
        {user.role === 'STUDENT' && (
          <NavLink onClick={toggleCollapsed} to="/tickets/all">
            <Menu.Item
              key="/tickets/all"
              id="tickets-base-hover"
              icon={
                <FeatherIcon
                  height={15}
                  icon="flag"
                  style={{
                    color:
                      user.role === 'STUDENT' && location.pathname === `/tickets/all`
                        ? theme['white-color']
                        : theme['light-gray-color'],
                    strokeWidth: 3,
                  }}
                />
              }
              className={
                user.role === 'STUDENT' && location.pathname === `/tickets/all`
                  ? 'selected-menu-item'
                  : 'normal-menu-item'
              }
            >
              Moje zgłoszenia
            </Menu.Item>
          </NavLink>
        )}
        {user.role === 'TEACHER' && (
          <NavLink onClick={toggleCollapsed} to={user.isActive ? '/tickets/all' : ''}>
            <Menu.Item key="/tickets/all" icon={<FeatherIcon icon={user.isActive ? 'flag' : 'lock'} />}>
              Moje zgłoszenia
            </Menu.Item>
          </NavLink>
        )}
        {(user.role === 'SENIOR-TEACHER' || user.role === 'ADMIN') && (
          <NavLink onClick={toggleCollapsed} to={user.isActive ? '/tickets/all' : ''}>
            <Menu.Item key="/tickets/all" icon={<FeatherIcon icon="flag" />}>
              Zgłoszenia
              <div
                style={{
                  width: '100%',
                }}
              >
                <span
                  style={{
                    marginRight: '25px',
                    minWidth: '15px',
                    textAlign: 'center',
                  }}
                  className="badge badge-success"
                >
                  {openedTickets}
                </span>
                {user.role === 'ADMIN' && (
                  <span className="badge badge-warning" style={{ minWidth: '15px', textAlign: 'center' }}>
                    {notAnsweredTickets}
                  </span>
                )}
              </div>
            </Menu.Item>
          </NavLink>
        )}
        {user.role === 'STUDENT' ? (
          <NavLink onClick={toggleCollapsed} to={user.isActive ? '/settings/profile' : ''}>
            <Menu.Item
              key="/settings"
              icon={
                <FeatherIcon
                  height={15}
                  icon={user.isActive ? 'user' : 'lock'}
                  style={{
                    color:
                      user.role === 'STUDENT' && location.pathname.includes(`/settings`)
                        ? theme['white-color']
                        : theme['light-gray-color'],
                    strokeWidth: 3,
                  }}
                />
              }
              className={location.pathname.includes(`/settings`) ? 'selected-menu-item' : 'normal-menu-item'}
              style={{
                cursor: user.isActive ? 'pointer' : 'not-allowed',
              }}
            >
              Moje konto
            </Menu.Item>
          </NavLink>
        ) : (
          <NavLink onClick={toggleCollapsed} to={user.isActive ? '/settings' : ''}>
            <Menu.Item key="/settings" icon={<FeatherIcon icon={user.isActive ? 'settings' : 'lock'} />}>
              Ustawienia
            </Menu.Item>
          </NavLink>
        )}
      </MenuFontProvider>
      {!collapsed && (
        <div
          style={{
            display: 'flex',
            marginRight: '15px',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'flex-end',
            fontSize: '10px',
            marginBottom: '50px',
            color: '#878eaa',
          }}
        >
          {version && <p>{`v${version}`}</p>}
        </div>
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
